import {
  Box,
  Button,
  Tooltip,
  TooltipProps,
  styled,
  tooltipClasses
} from "@mui/material";

export const MapContainer = styled(Box)({
  width: "100%",
  ".gm-style-iw.gm-style-iw-c": {
    background: "none",
    boxShadow: "none"
  },
  "button.gm-ui-hover-effect": {
    top: "6px !important",
    outline: "none",
    "box-shadow": "0",
    backgroundColor: "transparent !important",
    "& span": {
      backgroundColor: "#ffffff !important",
      content: "'X'",
      color: "#ffffff",
      fontWeight: "bold",
      fontSize: "14px"
    }
  },
  ".gm-style-iw-d": {
    overflow: "hidden !important"
  },
  ".gm-style .gm-style-iw-tc::after": {
    background: "#192026E0"
  },
  ".gmnoprint .gm-style-mtc": {
    display: "none"
  },
  "button.gm-control-active.gm-fullscreen-control": {
    display: "none"
  }
});

export const TilesWrapperBluer = styled("div")<{
  allObjectsLoading: boolean;
}>(({ allObjectsLoading }) => {
  return {
    filter: allObjectsLoading ? "blur(1000px)" : "none",
    transition: "filter 0.3s ease"
  };
});

export const MarkerLabel = styled(Box)({
  backgroundColor: "#1D1F2BB8",
  color: "#fff",
  fontWeight: 500,
  fontSize: "12px",
  lineHeight: "12px",
  padding: "8px",
  borderRadius: "4px",
  marginLeft: "11px"
});

export const MarkerBlip = styled(Box)({
  height: "6px",
  left: 0,
  position: "absolute",
  top: 0,
  transform: "translateX(-50%)",
  width: "12px",
  "::after": {
    clipPath: "polygon(0% 50%,50% 0%,50% 100%)",
    content: `""`,
    height: "16px",
    left: 5,
    position: "absolute",
    top: 6,
    width: "24px",
    background: "#192026c2"
  }
});

export const GeozoneNameBox = styled(Box)({
  backgroundColor: "#fff",
  color: "#000",
  fontWeight: 500,
  fontSize: "12px",
  lineHeight: "12px",
  padding: "8px",
  borderRadius: "2px",
  position: "relative",
  left: "-50%",
  top: "-21px"
});

export const GeozoneNameDownArrow = styled(Box)({
  height: "6px",
  left: "-10px",
  position: "absolute",
  top: 0,
  transform: "translateX(-50%)",
  width: "12px",
  "::after": {
    clipPath: "polygon(0% 0%, 100% 0%, 50% 100%)",
    content: `""`,
    height: "16px",
    left: 5,
    position: "absolute",
    top: 6,
    width: "24px",
    background: "#fff"
  }
});

export const MapWrapper = styled(Box)({});

export const SearchFieldWrapper = styled(Box)({
  background: "#fff",
  borderRadius: "6px"
});

export const TopLeftSection = styled(Box)({
  display: "flex",
  gap: "8px",
  position: "absolute",
  top: "16px",
  left: "16px",
  zIndex: 99
});

export const TopRightSection = styled(Box)({
  position: "absolute",
  top: "16px",
  right: "16px",
  zIndex: 99
});

export const MiniButton = styled(Button)({
  background: "#fff",
  minWidth: "0",
  "&:hover": {
    background: "#fff"
  }
});

export const IconsWrapper = styled(Box)({
  position: "absolute",
  top: 10,
  zIndex: 2,
  right: 10,
  display: "flex",
  flexDirection: "row-reverse",
  gap: "4px"
});

export const IconTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "rgba(0,0,0,0.7)",
    maxWidth: "120px",
    padding: "6px",
    textAlign: "center"
  }
}));

export const IconBox = styled(Box)<{ active: boolean; topMargin?: boolean }>(
  ({ active, topMargin }) => ({
    height: "35px",
    width: "35px",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "white",
    borderRadius: "4px",
    cursor: "pointer",
    display: "flex",
    marginTop: topMargin ? "165px" : 0,
    marginRight: topMargin ? "15px" : 0,
    border: active ? "1px solid grey" : "1px solid #D5D9E8",
    boxShadow: active ? "inset 0 0 5px #D5D9E8" : "none"
  })
);
