import React, { FC } from "react";
import { MarkerData } from "./types";
import {
  MapObjectCard,
  ObjectMetadataTags,
  utils
} from "@vilocnv/allsetra-core";
import {
  Skeleton,
  SkeletonWrapper
} from "components/maps/common/ObjectMarker.styled";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { isEmpty } from "lodash";
import { useAppSelector } from "hooks";
import { selectSpecificObjectState } from "app/data/selectors";

interface MarkerInfoWindowProps {
  marker: MarkerData;
}

const MarkerInfoWindowContent: FC<MarkerInfoWindowProps> = ({ marker }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { markerLoading, selectedMarker } = useAppSelector(
    selectSpecificObjectState
  );

  return (
    <MapObjectCard
      label={marker.name || t("typography.na")}
      id={marker.aNumber || t("typography.na")}
      loading={false}
      address={
        !isEmpty(selectedMarker)
          ? (selectedMarker?.location.resolvedAddress as string)
          : t("typography.na")
      }
      accuracy={marker?.accuracy || (t("typography.na") as any)}
      time={
        markerLoading
          ? (marker.formattedDate ?? "")
          : utils.formatDate({
              date: new Date(selectedMarker?.location?.date),
              dateFormat: "dd-MMM-yyyy, HH:mm"
            })
      }
      handleObjectNameClick={() =>
        navigate({
          pathname: `/dashboard/objects/details/${marker.uniqueId}`
        })
      }
      translator={t}
    >
      {!isEmpty(selectedMarker) && !markerLoading ? (
        <ObjectMetadataTags
          translator={t}
          object={selectedMarker}
          direction={"row"}
        />
      ) : (
        <SkeletonWrapper>
          <Skeleton variant="rectangular" />
        </SkeletonWrapper>
      )}
    </MapObjectCard>
  );
};

export default React.memo(MarkerInfoWindowContent);
